import { SuperFeatureProperty } from '@propertypal/shared/src/types/superFeature';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import PropertyPrice from '../property/PropertyPrice';
import { Text } from '../typography';
import { Address, Container, Content, CustomLink, CustomText, ImageWrapper, MobileText } from './ImageInfoBar.style';

interface Props {
  superFeatureProperty?: SuperFeatureProperty;
  active: boolean;
}

const ImageInfoBar: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const displayAddress =
    props.superFeatureProperty?.type === 'development'
      ? props.superFeatureProperty.displayAddressLine1
      : props.superFeatureProperty?.displayAddress;
  const shortenDisplayAddress = props.superFeatureProperty?.shortenDisplayAddress
    ? props.superFeatureProperty?.shortenDisplayAddress
    : displayAddress;

  const shareURL = props.superFeatureProperty?.path;
  const price = props.superFeatureProperty?.price;

  if (shareURL && price) {
    return (
      <Container active={props.active}>
        <CustomLink href={shareURL} data-testid="info-bar" prefetch={false}>
          <Content $column={props.superFeatureProperty && props.superFeatureProperty.listingLogos.length > 1}>
            <ImageWrapper>
              {props.active &&
                props.superFeatureProperty?.listingLogos
                  ?.slice(0, 3)
                  .map((logo) => (
                    <img key={logo.standard} src={logo.standard} alt="Agent Logo" height={30} width="auto" />
                  ))}
            </ImageWrapper>

            <CustomText color={theme.white} fontSize={14}>
              <Address>{displayAddress} -&nbsp;</Address>
              <PropertyPrice as="span" price={price} fontSize={14} color={theme.white} />
            </CustomText>

            <MobileText>
              <Text color={theme.white} fontSize={14}>
                {shortenDisplayAddress}
              </Text>
              <PropertyPrice price={price} fontSize={12} color={theme.white} />
            </MobileText>
          </Content>
        </CustomLink>
      </Container>
    );
  }

  return null;
};

export default ImageInfoBar;
